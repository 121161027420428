<template>
    <div  class="containerItens">
        <div class="container">
            <vs-row class="mt-20 mb-5 " vs-justify="center" vs-align="center" vs-type="flex" >
                <vs-col vs-w="2.5">
                  <div class="mx-2">
                    <vx-card class="cardFin overflow-hidden">
                        <template slot="no-body">
                            <div class="item-details" @click="Caixa()">
                                 <div class="my-1">
                                <vs-col  vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
                                    <span>
                                    <vs-icon icon="account_balance" class="my-3 img" size="50px" color="#002750"></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
                                    <span style="font-size: 19px" class="mb-10">CAIXA</span>
                                </vs-col>
                                </div>
                            </div>
                        </template>
                    </vx-card>
                    </div>
                </vs-col>
                <vs-col vs-w="2.5">
                  <div class="mx-2">
                    <vx-card class="cardFin overflow-hidden">
                        <template slot="no-body">
                            <div class="item-details px-1" @click="NotasEntrada()">
                                <div class="my-1">
                                <vs-col  vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
                                    <span>
                                    <vs-icon icon="money_off"  class="my-3 img"  size="50px" color="#002750"></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
                                    <span style="font-size: 19px" class="mb-10">CONTAS A PAGAR</span>
                                </vs-col>
                                </div>
                            </div>
                        </template>
                    </vx-card>
                    </div>
                </vs-col>
                <vs-col vs-w="2.5">
                  <div class="mx-2">
                    <vx-card class="cardFin overflow-hidden">
                        <template slot="no-body">
                            <div class="item-details px-1" @click="ContasReceber()">
                                <div class="my-1">
                                <vs-col  vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
                                    <span>
                                    <vs-icon icon="attach_money"  class="my-3 img"  size="50px" color="#002750"></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
                                    <span style="font-size: 19px" class="mb-10">CONTAS A RECEBER</span>
                                </vs-col>
                                </div>
                            </div>
                        </template>
                    </vx-card>
                    </div>
                </vs-col>
            </vs-row>
            <vs-row class="mt-20 mb-5 " vs-justify="center" vs-align="center" vs-type="flex" >
                <vs-col vs-w="2">
                  <div class="mx-2">
                    <vx-card class="cardFin overflow-hidden">
                        <template slot="no-body">
                            <div class="item-details px-1" @click="Boleto()">
                                <div class="my-1">
                                <vs-col  vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
                                    <span>
                                    <vs-icon icon="receipt"  class="my-3 img"  size="50px" color="#002750"></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
                                    <span style="font-size: 19px" class="mb-10">BOLETOS</span>
                                </vs-col>
                                </div>
                            </div>
                        </template>
                    </vx-card>
                    </div>
                </vs-col>
                <vs-col vs-w="2">
                  <div class="mx-2">
                    <vx-card class="cardFin overflow-hidden">
                        <template slot="no-body">
                            <div class="item-details px-1" @click="Notas()">
                                <div class="my-1">
                                <vs-col  vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
                                    <span>
                                    <vs-icon icon="description"  class="my-3 img"  size="50px" color="#002750"></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
                                    <span style="font-size: 19px" class="mb-10">NOTAS</span>
                                </vs-col>
                                </div>
                            </div>
                        </template>
                    </vx-card>
                    </div>
                </vs-col>
                <vs-col vs-w="2">
                  <div class="mx-2">
                    <vx-card class="cardFin overflow-hidden">
                        <template slot="no-body">
                            <div class="item-details px-1" @click="Retorno()">
                                <div class="my-1">
                                <vs-col  vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
                                    <span>
                                    <vs-icon icon="cached"  class="my-3 img"  size="50px" color="#002750"></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
                                    <span style="font-size: 19px" class="mb-10">RETORNO</span>
                                </vs-col>
                                </div>
                            </div>
                        </template>
                    </vx-card>
                    </div>
                </vs-col>
                <vs-col vs-w="2">
                  <div class="mx-2">
                    <vx-card class="cardFin overflow-hidden">
                        <template slot="no-body">
                            <div class="item-details px-1" @click="Cobranca()">
                                <div class="my-1">
                                <vs-col  vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
                                    <span>
                                    <vs-icon icon="thumbs_up_down"  class="my-3 img"  size="50px" color="#002750"></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
                                    <span style="font-size: 19px" class="mb-10">COBRANÇA</span>
                                </vs-col>
                                </div>
                            </div>
                        </template>
                    </vx-card>
                    </div>
                </vs-col>
                <vs-col vs-w="2">
                  <div class="mx-2">
                    <vx-card class="cardFin overflow-hidden">
                        <template slot="no-body">
                            <div class="item-details px-1" @click="DRE()">
                                <div class="my-1">
                                <vs-col  vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
                                    <span>
                                    <vs-icon icon="folder"  class="my-3 img"  size="50px" color="#002750"></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
                                    <span style="font-size: 19px" class="mb-10">DRE</span>
                                </vs-col>
                                </div>
                            </div>
                        </template>
                    </vx-card>
                    </div>
                </vs-col>
                 <vs-col vs-w="2">
                   <div class="mx-2">
                    <vx-card class="cardFin overflow-hidden">
                        <template slot="no-body">
                            <div class="item-details px-1" @click="ParaFaturar()">
                                <div class="my-1">
                                <vs-col  vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
                                    <span>
                                    <vs-icon icon="local_atm"  class="my-3 img"  size="50px" color="#002750"></vs-icon>
                                    </span>
                                </vs-col>
                                <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
                                    <span style="font-size: 19px" class="mb-10">PARA FATURAR</span>
                                </vs-col>
                                </div>
                            </div>
                        </template>
                    </vx-card>
                    </div>
                </vs-col>
            </vs-row>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        async Caixa() {
            this.$router.push('/caixa')
        },
        async Boleto() {
            this.$router.push('/boleto')
        },
        async Notas() {
          this.$router.push('/nota')
        },
        async ContasReceber() {
            this.$router.push('/contas-receber')
        },
        async NotasEntrada() {
            this.$router.push('/notas-entrada')
        },
        async Retorno() {
          this.$router.push('/retorno')
        },
        async Cobranca() {
          this.$router.push('/cobranca')
        },
        async DRE() {
          this.$router.push('/DRE')
        },
        async ParaFaturar() {
          this.$router.push('/contratos-faturamento')
        }
    }
}
</script>
<style lang="scss" scoped>
.containerItens {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
}
.cardFin {
  transition-duration: 50ms;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: #002750;
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    color: white;
    cursor: pointer;
    .img {
     color: white !important;
    }

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
